<template>
    <b-col md="6" sm="12">
      <div class="card card-custom gutter-b">
      <!--begin::Header-->
        <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Hospedaje</h3>
            <div class="card-toolbar">
                <div 
                  class="btn btn-info btn-sm font-size-sm font-weight-bolder"
                  @click="SendData()"
                  v-b-toggle.theme-design-sidebar
                >
                  Añadir
                </div>
                <!-- <Dropdown4
                :btnClass="'btn btn-light btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75'"
                :btnText="'Create'"
                ></Dropdown4> -->
            </div>
            </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-2">
            <template v-for="(item, i) in Hospedajes">
            <!--begin::Item-->
            <div v-bind:key="i" class="mb-3">
                <div
                class="d-flex align-items-center mb-2"
            >
                <!--begin::Symbol-->
                <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                    class="symbol-label"
                    :style="`background-image:url(${item.text0})`"
                ></div>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                <a
                    class="text-dark-75 font-weight-bolder text-hover-primary"
                    @click="SendData(item)"
                    v-b-toggle.theme-design-sidebar
                    >{{ item.text1 }}</a
                >
                <span class="text-muted font-weight-bold font-size-sm"
                    >Telefono:
                    <span class="text-primary font-weight-bold">{{
                    item.text3
                    }}</span></span
                >
                </div>
                <div>
                <a
                    class="btn btn-icon btn-light btn-hover-danger btn-sm pulse"
                >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                    <inline-svg src="media/svg/icons/General/Trash.svg" />
                    <!--end::Svg Icon-->
                    </span>
                </a>
                </div>
                <!--end::Title-->
            </div>
            <span class="text-muted font-weight-bold font-size-sm my-1">{{ item.text2 }} </span>
            </div>
            <!--end::Item-->
            </template>
        </div>
      <!--end::Body-->
      </div>
    </b-col>
</template>

<script>
export default {
    methods: {
      SendData(item) {
        this.selectData('Hospedaje', item ? item : {})
      }
    },
    props: {
      selectData: {
        type: Function,
        required: true,
      },
    },
    data() {
        return {
          Hospedajes: [
            {
              text0: "media/stock-600x400/img-10.jpg",
              text1: "HOTEL HYATT CENTRIC LEÓN",
              text2: "Manuel J. Clouthier 245, Jardines del Campestre, 37128 León, Gto.",
              text3: "477-124-5879",
            },
            {
              text0: "media/stock-600x400/img-17.jpg",
              text1: "HOTEL CROWN PLAZA LEÓN",
              text2: "Blvd. Paseo de los Insurgentes # 3356 Col, San Jose de las Piletas, 37300 León, Gto",
              text3: "477-124-5879",
            },
          ],
        }
    },
}
</script>