<template>
    <b-row class="mb-9">
        <b-col md="3" sm="6" 
            v-for="(mesa, index) in list"
            :key="index"
        >
          <div v-if="mesa.type === 'Link'" class="card card-custom bg-light-danger card-shadowless mb-4" style="position: relative; ">
            <!--begin::Body-->
            <div 
              class="card-body" 
              style="padding: 10px;"
              @click="SendData(mesa)"
              v-b-toggle.theme-design-sidebar
            >
              <div class="text-center">
              
                <i class="las la-gift text-danger" style="font-size: 40px;"></i>
                <h4 class="font-weight-bold my-2 text-danger">Liverpool</h4>
                <div class=" text-danger">Link</div>
              </div>
            </div>
            <div
              class="symbol symbol-circle symbol-30 symbol-light mr-3 flex-shrink-0"
              style="position: absolute; top:-15px; right:-25px ; "
            >
              <div class="symbol-label" style="background-color: rgba(255,0,0,0.1) !important;">
                <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                  <i class="lar la-times-circle" style="color: #FF544E !important;"></i>
                  <!--end::Svg Icon-->
                </span>
              </div>
            </div>
            <!--end:: Body-->
          </div>

          <div v-if="mesa.type === 'Banco'" class="card card-custom bg-light-primary card-shadowless mb-4" style="position: relative; ">
            <!--begin::Body-->
            <div
              class="card-body" 
              style="padding: 10px;"
              @click="SendData(mesa)"
              v-b-toggle.theme-design-sidebar
            >
              <div class="text-center">
                
                <i class="las la-gift text-primary" style="font-size: 40px;"></i>
                <h4 class="font-weight-bold my-2 text-primary">BBVA</h4>
                <div class=" text-primary">Cuenta CLABE</div>
              </div>
            </div>
            <div
              class="symbol symbol-circle symbol-30 symbol-light mr-3 flex-shrink-0"
              style="position: absolute; top:-15px; right:-25px ; "
            >
              <div class="symbol-label" style="background-color: rgba(255,0,0,0.1) !important;">
                <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
                  <i class="lar la-times-circle" style="color: #FF544E !important;"></i>
                  <!--end::Svg Icon-->
                </span>
              </div>
            </div>
            <!--end:: Body-->
          </div>
        </b-col>
        <b-col md="3" sm="6">
          <b-card
            @click="SendData()"
            v-b-toggle.theme-design-sidebar
          >
            <div class="card-body" style="padding: 7px;">
              <div class="text-center">
                <i class="fa fa-solid fa-plus" style="font-size: 20px;"></i>
              </div>
            </div>
          </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
  data() {
      return {
          list: [
          {
            type: 'Link',
          },
          {
            type: 'Banco',
          },
          {
            type: 'Banco',
          },
          {
            type: 'Link',
          },
          {
            type: 'Banco',
          },
        ],
      }
  },
  methods: {
    SendData(item) {
      this.selectData('MesaRegalos', item ? item : {})
    }
  },
  props: {
    selectData: {
      type: Function,
      required: true,
    },
  },
}
</script>