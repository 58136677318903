<template>
    <b-sidebar
      id="theme-design-sidebar"
      shadow
      right
      bg-variant="white"
      backdrop
      width="400px"
    >
      <div style="padding: 35px;">
        <span class="text-dark-75 font-weight-bolder text-hover-primary" style="font-size: 15px;">
          {{ dataSide.type }}
        </span>

        <b-row v-if="dataSide.type === 'ImageSlider'" class="mt-5">
            <b-col>
                <b-form-file 
                 multiple
                 v-model="ImageFiles"
                 placeholder="Selecciona tus imagenes"
                >
                 <template slot="file-name" slot-scope="{ names }">
                   <b-badge variant="dark">{{ names[0] }}</b-badge>
                   <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                     + {{ names.length - 1 }} More files
                   </b-badge>
                 </template>
                </b-form-file>
            </b-col>
        </b-row>

        <b-row v-else-if="dataSide.type === 'MesaRegalos'" class="mt-5">
            <b-col sm="12">
              <b-form-group
                label="Selecciona el tipo de recurso"
                label-for="Selecciona el tipo de recurso"
              >
               <b-form-select
                 v-model="sourceSelected"
                 :options="TypeGiftSource"
                 value-field="Id"
                 text-field="Name"
                >
               </b-form-select> 
              </b-form-group>
            </b-col>
            <b-col v-if="sourceSelected === '1'" sm="12">
              <b-form-group
                label="Link de mesa de regalo"
                label-for="Link de mesa de regalo"
              >
               <b-form-input
                 v-model="LinkGiftTable"
                 type="text"
                 class="form-control-merge"
                 placeholder="Link"
               />
              </b-form-group>
            </b-col>
            <b-col  v-if="sourceSelected === '2'" sm="12">
              <b-form-group
                label="Nombre de Mesa de regalo"
                label-for="Nombre de Mesa de regalo"
              >
               <b-form-input
                 v-model="NameGiftTable"
                 type="text"
                 class="form-control-merge"
                 placeholder="Nombre"
               />
              </b-form-group>
            </b-col>
            <b-col  v-if="sourceSelected === '2'" sm="12">
              <b-form-group
                label="Destinatario"
                label-for="Destinatario"
              >
               <b-form-input
                 v-model="Destinatario"
                 type="text"
                 class="form-control-merge"
                 placeholder="Destinatario"
               />
              </b-form-group>
            </b-col>
            <b-col  v-if="sourceSelected === '2'" sm="12">
              <b-form-group
                label="Cuenta Clabe"
                label-for="Cuenta Clabe"
              >
               <b-form-input
                 v-model="CuentaClabe"
                 type="text"
                 class="form-control-merge"
                 placeholder="000-000-000-000-000"
               />
              </b-form-group>
            </b-col>
        </b-row>

        <b-row v-else-if="dataSide.type === 'Itinerario'" class="mt-5">
            <b-col sm="12">
              <b-form-group
                label="Nombre de Evento"
                label-for="Nombre de Evento"
              >
               <b-form-input
                 v-model="NombreItinerario"
                 type="text"
                 class="form-control-merge"
                 placeholder="Nombre de Evento"
               />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Descripción"
                label-for="Descripción"
              >
               <b-form-textarea
                 v-model="DescripcionItinerario"
                 id="textarea-default"
                 placeholder="Textarea"
                 rows="3"
               />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Hora del Evento"
                label-for="Hora del Evento"
              >
               <b-form-timepicker v-model="value" locale="en" placeholder="00:00:00"></b-form-timepicker>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Orden del Itinerario"
                label-for="Orden del Itinerario"
              >
               <b-form-spinbutton id="demo-sb" v-model="OrderValue" min="1" max="100"></b-form-spinbutton>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Google Maps Localizacion"
                label-for="Google Maps Localizacion"
              >
               <google-map />
              </b-form-group>
            </b-col>
        </b-row>

        <b-row v-else-if="dataSide.type === 'Hospedaje'" class="mt-5">
            <b-col sm="12">
              <b-form-group
                label="Nombre del Hospedaje"
                label-for="Nombre del Hospedaje"
              >
               <b-form-input
                 v-model="NombreHospedaje"
                 type="text"
                 class="form-control-merge"
                 placeholder="Nombre de Evento"
               />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Direccion Url"
                label-for="Direccion Url"
              >
               <b-form-input
                 v-model="UrlHospedaje"
                 type="text"
                 class="form-control-merge"
                 placeholder="https://pagina_hospedaje.com"
               />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Google Maps Localizacion"
                label-for="Google Maps Localizacion"
              >
               <google-map />
              </b-form-group>
            </b-col>
        </b-row>
      </div>
    </b-sidebar>
</template>

<script>
import GoogleMap from '../GoogleMaps/GoogleMap.vue'
export default {
  components: {
    GoogleMap,
  },
  props: {
    dataSide: {
      type: Object,
      required: true,
    },
  },
  data() {
    return{
      ImageFiles: [],
      sourceSelected: null,
      TypeGiftSource: [
        { Id: '1', Name: 'Link' }, 
        { Id: '2', Name: 'Banco' },
      ],
      LinkGiftTable: '',
      NameGiftTable: '',
      Destinatario: '',
      CuentaClabe: '',
      NombreItinerario: '',
      DescripcionItinerario: '',
      value: '',
      context: null,
      OrderValue: 1,
      NombreHospedaje: '',
      UrlHospedaje: '',
      TelefonoHospedaje: '',
      ImagenHospedaje: [],
    }
  },
  watch: {
    TypeGiftSourceSelected(){
    }
  },
  methods: {
    onContext(ctx) {
      this.context = ctx
    },
  }
}
</script>