<template>
<b-row class="mb-9">
    <b-col md="12">
        <div style="position: absolute; z-index: 2; padding: 30px;">
          <div class="card card-custom bg-light-success card-shadowless">
            <!--begin::Body-->
            <div class="card-body" style="padding: 5px;">
              <a
                class="font-weight-bolder text-success d-block"
                @click="SendData()"
                v-b-toggle.theme-design-sidebar
              >
                <h5 class="font-weight-bolder text-success" style="font-size: 12px; margin-bottom: 0px; margin-left: 5px; margin-right: 5px;">
                  <i class="fa fa-solid fa-plus text-success" style="font-size: 10px;"></i>
                  Añadir Imagenes Slider
                </h5>
              </a>
            </div>
            <!--end:: Body-->
          </div>
        </div>
        <b-carousel
          id="carousel-1"
          :interval="4000"
          fade
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333; border-radius: 10px; height: 250px;"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            img-src="https://media.istockphoto.com/photos/happy-wedding-photography-of-bride-and-groom-at-wedding-ceremony-picture-id1190043570?k=20&m=1190043570&s=612x612&w=0&h=4ucKegbD9AHd99kH5uEqrtm5zovyZ6IsYa33hR2pV-k="
          >
            <a
              class="btn btn-icon btn-light btn-hover-danger btn-sm pulse"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                <inline-svg src="media/svg/icons/General/Trash.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </b-carousel-slide>
          <!-- Slides with custom text -->
          <b-carousel-slide img-src="https://img.freepik.com/foto-gratis/novio-poniendo-anillo-dedo-novia_1157-338.jpg">
            <a
              class="btn btn-icon btn-light btn-hover-danger btn-sm pulse"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                <inline-svg src="media/svg/icons/General/Trash.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </b-carousel-slide>
          <!-- Slides with image only -->
          <b-carousel-slide img-src="https://us.123rf.com/450wm/smolnyi/smolnyi2005/smolnyi200500066/147757892-los-anillos-de-boda-se-encuentran-en-un-carrusel-de-juguete-con-pequeños-caballos-sobre-una-superfic.jpg?ver=6">
            <a
              class="btn btn-icon btn-light btn-hover-danger btn-sm pulse"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                <inline-svg src="media/svg/icons/General/Trash.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </b-carousel-slide>
        </b-carousel>
    </b-col>
</b-row>
</template>

<script>
export default {
  methods: {
    SendData() {
      this.selectData('ImageSlider',{})
    }
  },
  props: {
    selectData: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style>
.carousel-item {
  height: 250px;
  border-radius: 10px !important;
}
.img-fluid {
  height: 250px;
  border-radius: 10px !important;
}
</style>