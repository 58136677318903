<template>
    <b-col md="6" sm="12">
      <div class="card card-custom card-shadowless gutter-b bg-light">
        <!--begin::Header-->
        <div class="card-header border-0">
          <h3 class="card-title font-weight-bolder text-dark">Itinerario</h3>
          <div class="card-toolbar">
            <div 
              class="btn btn-info btn-sm font-size-sm font-weight-bolder"
              @click="SendData()"
              v-b-toggle.theme-design-sidebar
            >
              Añadir
            </div>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-2">
          <template v-for="(item, i) in Itinerario">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-10" v-bind:key="i">
              <!--begin::Bullet-->
              <span
                class="bullet bullet-bar align-self-stretch"
                v-bind:class="`bg-${item.style}`"
              ></span>
              <!--end::Bullet-->
              <!--begin::Checkbox-->
              <label
                class="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4"
                v-bind:class="`checkbox-light-${item.style}`"
              >
                <span style="display: block; text-align: center;">{{ i + 1 }}</span>
              </label>
              <!--end::Checkbox-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <a
                  class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                  @click="SendData(item)"
                  v-b-toggle.theme-design-sidebar
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-weight-bold">
                  {{ item.desc }}
                </span>
              </div>
              <!--end::Text-->
              <!--begin::Dropdown-->
              <a
                class="btn btn-icon btn-light btn-hover-danger btn-sm pulse"
              >
                <span class="svg-icon svg-icon-md svg-icon-danger">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                  <inline-svg src="media/svg/icons/General/Trash.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
              <!--end::Dropdown-->
            </div>
            <!--end:Item-->
          </template>
        </div>
        <!--end::Body-->
      </div>
    </b-col>
</template>

<script>
export default {
  data() {
    return{
      Itinerario: [
         {
           title: "Ceremonia Religiosa",
           desc: "Iglesia San Josemaría Escrivá de Balaguer",
           style: "success",
         },
         {
           title: "Recepción",
           desc: "Salón Barberini",
           style: "primary",
         },
         {
           title: "Post-Boda",
           desc: "ZOÍ Sur Residencial, casa club",
           style: "warning",
         },
         {
           title: "Fotografias",
           desc: "bosque gran jardin",
           style: "info",
         },
         {
           title: "Project Retro",
           desc: "Jardines de la Santa",
           style: "danger",
         },
       ],
    }
  },
  methods: {
    SendData(item) {
      this.selectData('Itinerario', item ? item : {})
    }
  },
  props: {
    selectData: {
      type: Function,
      required: true,
    },
  },
}
</script>