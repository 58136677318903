<template>
    <div>
        <b-row>
            <b-col lg="7" md="12">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 py-5">
                      <h3 class="card-title">
                        <span class="card-label font-weight-bolder text-dark" style="display: flex; align-items: center;">
                          Nombre del Evento
                        </span>
                      </h3>
                      <div class="card-toolbar">
                        <b-row>
                            <b-col md="12" class="font-weight-bolder text-dark">
                                12 / 04 / 05
                            </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="card-body">
                        <carousel :select-data="ChangeDataSide" />
                        <mesa-regalos :select-data="ChangeDataSide" />
                        <b-row>
                          <itinerario :select-data="ChangeDataSide" />
                          <hospedajes :select-data="ChangeDataSide" />
                        </b-row>
                    </div>
                </div>
            </b-col>
            <b-col lg="5" md="12">
              <div class="card card-custom card-stretch gutter-b">
                    <div class="card-body">
                      Otros componentes futuros ...
                    </div>
                </div>
            </b-col>
        </b-row>
        <theme-design-sidebar :data-side="DataSidebar" />
    </div>
</template>

<script>
import Carousel from './Carousel.vue'
import MesaRegalos from './MesaRegalos.vue'
import Itinerario from './Itinerario.vue'
import Hospedajes from './Hospedajes.vue'
import ThemeDesignSidebar from '../../SideBars/ThemeDesignSidebar.vue'

export default {
  components: {
    Carousel,
    MesaRegalos,
    Itinerario,
    Hospedajes,
    ThemeDesignSidebar,
  },
  data() {
    return{
      DataSidebar: {},
    }
  },
  methods: {
    ChangeDataSide(type, data){
      this.DataSidebar = {type, info: data}
    }
  },
}
</script>

<style scoped>

</style>